<template>
	<b-dropdown
		v-bind:text="post ? post.message : null"
		variant="outline-primary"
		@show="postShow"
	>
		<b-dropdown-item
			v-for="p in posts"
			:key="p.id"
			@click="() => postClick(p)"
		>
			{{ p.message.split('\n')[0] }}
		</b-dropdown-item>
	</b-dropdown>
</template>
<script>
import { fbService, cacheService } from '../services'
/* eslint-disable no-unused-vars */
import { PostCache, PostData } from '../services'
/* eslint-enable no-unused-vars */
export default {
	data() {
		return {
			/**
			 * @type {PostCache}
			 */
			post: { message: 'not selected', id: -1 },
			/**
			 * @type {PostData[]}
			 */
			posts: [],
		}
	},
	created() {
		this.post = cacheService.getPost()
	},
	methods: {
		async postShow() {
			const pageCache = cacheService.getPage()
			const accessToken = fbService.getAccessToken()
			const response = await fbService.getPosts(accessToken, pageCache.id)
			this.posts = response.data
		},

		postClick(post) {
			const cache = {
				id: post.id,
				message: post.message.split('\n')[0],
			}
			cacheService.setPost(cache)
			this.post = cache
			console.log(this.post)
		},
	},
}
</script>
