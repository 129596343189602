<template>
	<b-dropdown
		v-bind:text="page ? page.name : null"
		variant="outline-primary"
		@show="pageShow"
	>
		<b-dropdown-item
			v-for="p in pages"
			:key="p.id"
			@click="() => pageClick(p)"
		>
			{{ p.name }}
		</b-dropdown-item>
	</b-dropdown>
</template>
<script>
import { fbService, cacheService } from '../services'
/* eslint-disable no-unused-vars */
import { PageCache, PageData } from '../services'
/* eslint-enable no-unused-vars */
export default {
	data() {
		return {
			/**
			 * @type {PageCache}
			 */
			page: { name: 'not selected' },
			/**
			 * @type {PageData[]}
			 */
			pages: [],
		}
	},
	created() {
		this.page = cacheService.getPage()
	},
	methods: {
		async pageShow() {
			const accessToken = fbService.getAccessToken()
			const accountResponse = await fbService.getMeAccounts(accessToken)
			this.pages = accountResponse.data
		},

		pageClick(page) {
			const cache = {
				id: page.id,
				name: page.name,
				accessToken: page.accessToken,
			}
			cacheService.setPage(page)
			this.page = cache
			console.log(this.page)
		},
	},
}
</script>
