<template>
	<div>
		<table class="table col-12" v-columns-resizable>
			<thead>
				<tr>
					<th width="25%" class="table-resize-bar">Picture</th>
					<th width="65%" class="table-resize-bar">Description</th>
					<th width="10%">Status</th>
				</tr>
			</thead>
			<tbody>
				<template v-for="entry in entries">
					<Entry
						:key="entry.id"
						v-on:delete="() => handleDelete(entry.id)"
						:entry="entry"
					/>
				</template>
				<tr class="new-post-tr">
					<td colspan="3">
						<div
							class="new-post"
							@drop.prevent="onDrop"
							@dragover.prevent
							@dragenter.prevent="onDragEnter"
							@dragleave.prevent="onDragLeave"
						>
							<div :class="dropClass">Drop image here</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import { cacheService, entryService, fbService } from '../services'
import Entry from '@/components/Entry.vue'
import { CancellationToken } from '../services'

export default {
	props: {
		token: CancellationToken,
	},
	data() {
		return {
			/**
			 * @type {import('../services').Entry}
			 */
			entries: [],
			dropClass: 'drop-area',
		}
	},
	async created() {
		this.entries = await entryService.getEntries()
		console.log(this.entries)
	},
	mounted() {
		this.token.onPost = this.post
	},
	components: {
		Entry,
	},
	methods: {
		//drop imagefile and save
		onDrop(evt) {
			this.dropClass = 'drop-area'
			let files = evt.dataTransfer.files
			console.log(files)
			for (let i = 0; i < files.length; i++) {
				const file = files[i]
				const reader = new FileReader()
				reader.onload = async (f) => {
					const src = f.target.result
					/**
					 * @type {import('../services').Entry}
					 */
					let data = {
						img: src,
						description: '',
						status: 'ready',
					}
					const id = await entryService.addEntry(data)
					const entry = await entryService.getEntry(id)
					this.entries.push(entry)
					console.log(entry)
				}
				reader.readAsDataURL(file)
			}
		},
		onDragEnter() {
			this.dropClass = 'drop-area drop-area-enter'
		},
		onDragLeave() {
			this.dropClass = 'drop-area'
		},
		handleInput(input, index) {
			this.datas[index] = input
		},
		handleDelete(id) {
			entryService.removeEntry(id)
			const index = this.entries.findIndex((i) => i.id === id)
			this.entries.splice(index, 1)
		},
		async post() {
			this.token.status = 'running'
			await this.reloadPageToken()
			let isCancel = false
			console.log(this.entries)
			for (const index in this.entries) {
				const element = this.entries[index]
				console.log({
					action: 'post',
					entry: element,
					index,
				})
				let success = false
				do {
					success = await element.postAsync()
					await this.sleep(3000)
					if (this.token.isCancel) {
						isCancel = true
						this.token.isCancel = false
					}
					if (isCancel) break
				} while (!success)
				if (isCancel) break
			}
			this.token.status = 'success'
		},
		async reloadPageToken() {
			const accessToken = fbService.getAccessToken()
			const pageCache = cacheService.getPage()
			const accountResponse = await fbService.getMeAccounts(accessToken)
			const page = accountResponse.data.filter(
				(i) => i.id == pageCache.id
			)[0]
			cacheService.setPage({
				id: page.id,
				name: page.name,
				access_token: page.access_token,
			})
		},
		sleep(ms) {
			return new Promise((resolve) => setTimeout(resolve, ms))
		},
	},
}
</script>
<style>
#postlist {
	width: 100%;
}
.table-resize-bar {
	border-right: 2px solid #0a0a0a;
}
.new-post-tr {
	background-color: #dfdfdf;
}
.new-post {
	min-height: 250px;
}
.drop-area-enter {
	background-color: #c5c5c5;
}
.drop-area {
	margin: 15px;
	border: 4px dashed #0a0a0a;
	min-height: 250px;
	justify-content: center;
	padding: auto;
	border-radius: 30px;
}
.description {
	width: 100%;
}
</style>
