import { openDB } from 'idb'

const entryDBName = 'entry-storage'
const entryObjectName = 'entries'

/**
 * @typedef {Object} Entry
 * @property {number} id
 * @property {Object} img
 * @property {string} description
 * @property {('ready'|'posting'|'posted')} status
 */

class serviceClass {
	constructor() {}
	/**
	 * @returns {Promise<import('idb').IDBPDatabase<unknown>}
	 */
	get() {
		if (!this.db) {
			this.db = openDB(entryDBName, undefined, {
				upgrade(db) {
					db.createObjectStore(entryObjectName, {
						keyPath: 'id',
						autoIncrement: true,
					})
				},
			})
		}
		return this.db
	}

	async getEntries() {
		const db = await this.get()
		return await db.getAll(entryObjectName)
	}

	/**
	 * @param {Entry} entry
	 * @returns {Promise<number>}
	 */
	async addEntry(entry) {
		const db = await this.get()
		return await db.add(entryObjectName, entry)
	}

	/**
	 * @param {number} id
	 * @returns {Promise<Entry>}
	 */
	async getEntry(id) {
		const db = await this.get()
		return await db.get(entryObjectName, id)
	}

	/**
	 * @param {number} id
	 * @param {Entry} value
	 */
	async setEntry(value) {
		const db = await this.get()
		await db.put(entryObjectName, value)
	}

	/**
	 *@param {number} id
	 */
	async removeEntry(id) {
		const db = await this.get()
		await db.delete(entryObjectName, id)
	}
}
export const entryService = new serviceClass()
