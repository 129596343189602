import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueColumnsResizable from './plugins/vue-columns-resizable'
import { initFacebookSdk } from './helper'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(VueColumnsResizable)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

//Vue.http.headers.common['Access-Control-Allow-Origin'] = '*'
Vue.config.productionTip = false

initFacebookSdk().then(() => {
	new Vue({
		router,
		headers: {
			'Access-Control-Allow-Origin': '*',
		},
		render: (h) => h(App),
	}).$mount('#app')
})
