<template>
	<div class="home">
		<div v-for="(msg, index) of messages" :key="index">
			<Error v-bind:message="msg" />
		</div>
		<FacebookLogin v-on:push-error="pushError" :token="cancellationToken" />
		<entries :token="cancellationToken" />
	</div>
</template>

<script>
// @ is an alias to /src
import FacebookLogin from '@/components/FacebookLogin.vue'
import Error from '@/components/Error.vue'
import Entries from '../components/Entries.vue'
import { CancellationToken } from '../services'

export default {
	name: 'Home',
	data() {
		return {
			messages: [],
			cancellationToken: new CancellationToken(),
		}
	},
	components: {
		FacebookLogin,
		Entries,
		Error,
	},
	methods: {
		pushError(error) {
			console.log(error)
			this.messages.push(error.message)
		},
	},
}
</script>
