/*global FB*/
import axios from 'axios'

export const fbService = {
	login,
	logout,
	getLoginStatus,
	getAuthResponse,
	getAccessToken,
	getMeName,
	getMeAccounts,
	getPosts,
	comment,
}
const graphUrl = 'https://graph.facebook.com'
const version = 'v11.0'
/**
 * facebook auth response
 * @typedef {Object} AuthResponse
 * @property {string} accessToken
 * @property {number} data_access_expiration_time
 * @property {number} expiresIn
 * @property {string} graphDomain
 * @property {string} signedRequest
 * @property {string} userID
 *
 * @typedef {Object} MeResponse
 * @property {string} id
 * @property {string} name
 *
 * @typedef {Object} LoginStatus
 * @property {AuthResponse} authResponse
 * @property {('connected'|'not_authorized'|'unknown')} status
 *
 * @typedef {Object} PagingCursors
 * @property {string} before
 * @property {string} after
 *
 * @typedef {Object} Paging
 * @property {PagingCursors} cursors
 *
 * @typedef {Object} PageData
 * @property {string} access_token
 * @property {string} category
 * @property {Object} category_list
 * @property {string} name
 * @property {string} id
 * @property {string[]} tasks
 *
 * @typedef {Object} AccountsResponse
 * @property {PageData[]} data
 * @property {Paging} paging
 *
 * @typedef {Object} PostData
 * @property {string} created_time
 * @property {string} message
 * @property {string} id
 *
 * @typedef {Object} PostResonse
 * @property {PostData[]} data
 * @property {Paging} paging
 */

/**
 * @returns {Promise<LoginStatus>}
 */
async function login() {
	return await new Promise((resolve) => {
		FB.login(
			(response) => {
				resolve(response)
			},
			{
				scope: 'pages_manage_engagement,pages_read_user_content,pages_manage_posts',
			}
		)
	})
}

function logout() {
	FB.logout()
}

/**
 * @returns {LoginStatus}
 */
async function getLoginStatus() {
	return await new Promise((resolve) => {
		FB.getLoginStatus((data) => {
			resolve(data)
		})
	})
}

/**
 * get facebook auth response
 * @returns {AuthResponse}
 */
function getAuthResponse() {
	return FB.getAuthResponse()
}

function getAccessToken() {
	const { accessToken } = getAuthResponse()
	return accessToken
}

/**
 * @param {string} accessToken
 * @returns {Promise<MeResponse>}
 */
async function getMeName(accessToken) {
	const { data } = await axios.get(
		`${graphUrl}/me?fields=name&access_token=${accessToken}`
	)
	return data
}

/**
 * @param {string} accessToken
 * @returns {Promise<AccountsResponse>}
 */
async function getMeAccounts(accessToken) {
	const { data } = await axios.get(
		`${graphUrl}/${version}/me/accounts?access_token=${accessToken}`
	)
	return data
}

/**
 * @param {string} accessToken
 * @param {string} userId
 * @returns {Promise<PostResonse>}
 */
async function getPosts(accessToken, userId) {
	const { data } = await axios.get(
		`${graphUrl}/${version}/${userId}/posts?access_token=${accessToken}`
	)
	return data
}

/**
 * @callback onUploadProgress
 * @param {ProgressEvent} progressEvent
 */

/**
 * @param {onUploadProgress} onUploadProgress
 */
async function comment(
	pageToken,
	postId,
	commentText,
	image,
	onUploadProgress
) {
	const accessToken = pageToken
	let formData = new FormData()
	const file = dataURItoBlob(image)
	formData.append('source', file)
	formData.append('message', commentText)
	const result = await axios.post(
		`${graphUrl}/${version}/${postId}/comments?access_token=${accessToken}`,
		formData,
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			onUploadProgress,
		}
	)
	return result
}

function dataURItoBlob(dataURI) {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
	var byteString = atob(dataURI.split(',')[1])

	// separate out the mime component
	var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

	// write the bytes of the string to an ArrayBuffer
	var ab = new ArrayBuffer(byteString.length)

	// create a view into the buffer
	var ia = new Uint8Array(ab)

	// set the bytes of the buffer to the correct values
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i)
	}

	// write the ArrayBuffer to a blob, and you're done
	var blob = new Blob([ab], { type: mimeString })
	return blob
}
