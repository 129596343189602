export class CancellationToken {
	constructor() {}
	isCancel = false
	/**
	 * @type {('unknown'|'running'|'success')}
	 */
	status = 'unknown'
	onPost = null
	cancel() {
		this.isCancel = true
	}
	post() {
		this.onPost()
	}
}
