export const cacheService = {
	getUserName,
	setUserName,
	getPage,
	setPage,
	getPost,
	setPost,
	clear,
}

/**
 * @typedef {Object} PageCache
 * @property {string} id
 * @property {string} name
 * @property {string} access_token
 */

/**
 * @typedef {Object} PostCache
 * @property {string} id
 * @property {string} message
 */

const userNameKey = 'user-name'
const pageKey = 'page'
const postKey = 'post'

function getUserName() {
	return localStorage.getItem(userNameKey)
}

/**
 * @param {string} userId
 */
function setUserName(userName) {
	localStorage.setItem(userNameKey, userName)
}

/**
 * @returns {PageCache}
 */
function getPage() {
	return JSON.parse(localStorage.getItem(pageKey))
}

/**
 * @param {PageCache} page
 */
function setPage(page) {
	localStorage.setItem(pageKey, JSON.stringify(page))
}

/**
 * @returns {PostCache}
 */
function getPost() {
	return JSON.parse(localStorage.getItem(postKey))
}

/**
 * @param {PostCache} post
 */
function setPost(post) {
	localStorage.setItem(postKey, JSON.stringify(post))
}

function clear() {
	localStorage.removeItem(postKey)
	localStorage.removeItem(pageKey)
	localStorage.removeItem(userNameKey)
}
